@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  transition: all 0.5s;
}

/* loader */
.bg-loader {
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-loader .box {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 10px solid white;
  border-top-color: black;
  animation: loader 1.5s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* usable tools */
.container {
  max-width: 1320px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

a {
  text-decoration: none;
  color: black;
}

.text-center {
  text-align: center;
}

.bg {
  width: 100%;
  height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg h1 {
  font-size: 100px;
}

/* navigation part */
header {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 15px black;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

header img {
  width: 120px;
}

header nav .navLinks {
  display: flex;
  gap: 60px;
}

header nav .navLinks a {
  padding: 25px 0;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

header nav .navLinks a:hover {
  color: black;
  letter-spacing: 1px;
}

/* bannersection */
.banner-bg {
  width: 100%;
  margin: 100px 0;
  overflow: hidden;
  height: 70vh;
  background-color: black;
  text-align: center;
}

.banner-bg h1 {
  color: white;
  margin: 20px 0 0;
  font-size: 50px;
  font-weight: 500;
  text-align: center;
}

.banner-bg h2 {
  color: white;
  font-size: 35px;
  font-weight: 400;
  text-align: center;
}

.banner-bg a {
  position: relative;
  color: #2997ff;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 20px 25px 100px;
}

.banner-bg a::after {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  background-color: #2997ff;
  bottom: 0;
  right: 0;
  transition: all 0.5s;
}

.banner-bg a:hover::after {
  width: 100%;
  left: 0;
}

.banner-bg a:hover {
  transform: scale(105%);
}

.banner-bg a i {
  font-size: 13px;
}

.banner-bg video {
  margin: 0 auto;
  display: block;
  width: 70%;
}

/* home section */
.home-section {
  background-image: url("https://cdn.shopify.com/s/files/1/0183/5769/files/rugged_banner_full_a67c7f70-30ae-4d1e-946a-cc90d9495223_2000x.png?v=1651714140");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.home-section .overlay {
  background-color: rgba(0, 0, 0, 0.7);
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-section .overlay h1 {
  font-size: 80px;
  color: white;
  font-weight: 400;
  text-align: center;
}

.home-section .overlay p {
  width: 55%;
  text-align: center;
  color: white;
  margin: 20px auto 40px;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
}

.home-section .overlay a {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: fit-content;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid white;
  padding: 12px 24px;
}

.home-section .overlay a:hover {
  background-color: white;
  color: black;
}

/* products section */
.product-section {
  padding-top: 100px;
}

.product-section .product-selection {
  width: 28%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 1000px;
  padding: 15px;
  border-radius: 15px;
  position: sticky;
  top: 10px;
}

.product-section .product-selection .search {
  width: 82%;
  height: 50px;
  border-radius: 10px 0 0 10px;
  border: 1px solid black;
  font-size: 18px;
  color: black;
  font-weight: 500;
  padding-left: 15px;
  outline: none;
  margin-bottom: 20px;
}

.product-section .product-selection .fa-magnifying-glass {
  width: 18%;
  background-color: black;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 10px 10px 0;
  border: 1px solid black;
  font-size: 19px;
  cursor: pointer;
}

.product-section .product {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 72%;
}

.product-section .productBox {
  position: relative;
  height: 400px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px;
  border-radius: 15px;
  overflow: hidden;
}

.product-section .product img {
  width: 100%;
  height: 230px;
}

.product-section .productBox .content {
  padding: 15px;
}

.product-section .productBox .content h1 {
  color: black;
  font-size: 22px;
  font-weight: 600;
}

.product-section .productBox .content span {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
  display: block;
}

.product-section .productBox .content p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.product-section .product .productBox:hover {
  transform: scale(103%);
}

/* product details section */
.productDetails-section {
  background-color: cyan;
  height: 100vh;
}

.productDetails-section .container {
  display: flex;
  align-items: center;
  height: 100vh;
}

.productDetails-section .row {
  box-shadow: 0px 0px 15px black;
  width: 100%;
  height: 400px;
  background-color: white;
}

.productDetails-section .row .productDetails {
  width: 60%;
  padding: 15px;
}

.productDetails-section .row .productImage img {
  width: 100%;
  height: 100%;
}

.productDetails-section .productImage {
  width: 40%;
  height: 325px;
  padding: 15px;
  text-align: center;
}

.productImage button,
.productDetails button {
  position: relative;
  background-color: blue;
  color: white;
  padding: 12px 30px;
  border: none;
  display: inline-block;
  margin: 20px 50px 0 0;
  margin-left: 0px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-decoration: none;
}

.productImage button:hover,
.productDetails button:hover {
  background-color: black;
}

.productImage button::after,
.productDetails button::after {
  position: absolute;
  content: "";
  top: -50px;
  left: -38px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 35px;
  height: 200px;
  transform: rotate(-40deg);
  transition: all 1s;
}

.productImage button:hover::after,
.productDetails button:hover::after {
  left: 195px;
}

.productDetails .title {
  text-align: center;
  text-transform: capitalize;
  font-size: 40px;
  font-weight: 600;
  color: black;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
}

.productDetails .description {
  color: black;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 0;
}

.productDetails .price {
  color: black;
  font-size: 18px;
  font-weight: 600;
}

.productDetails .disc {
  color: red;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
}

.productDetails .rating {
  background-color: green;
  margin: 10px 0;
  width: fit-content;
  padding: 5px;
  color: white;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
}

.productDetails .rating i {
  font-size: 10px;
  margin-left: 3px;
}

.productDetails .brand {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.productDetails .category {
  margin: 5px 0 15px;
  font-size: 18px;
  font-weight: 400;
  color: black;
  text-transform: capitalize;
}

/* signup page */
.form {
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 1) 35%,
      rgba(0, 212, 255, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form form {
  color: white;
  padding: 25px;
  width: 530px;
  height: fit-content;
  box-shadow: 0px 0px 20px black;
  border-radius: 5px;
}

.form form label {
  display: inline-block;
  width: 38%;
  padding-right: 20px;
  font-size: 16px;
  font-weight: 400;
}

.form form input {
  display: inline-block;
  width: 62%;
  padding: 3px 0;
  margin-bottom: 60px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
  outline: none;
  padding-left: 20px;
  color: white;
  text-transform: capitalize;
}

.form form input:focus {
  border-color: white;
}

.form form #submit {
  padding: 0;
  width: fit-content;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin: 35px auto 0;
  border-radius: 5px;
  color: white;
  border: 1px solid white;
  text-decoration: none;
  cursor: pointer;
}

.form form #submit:hover {
  background-color: white;
  color: black;
}

.form form h2 {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.form form .other-links {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.form form .other-links a {
  font-size: 25px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.1s;
}

.form form .other-links a:hover {
  color: white;
}

/* cart page */
.cart-page {
  background-color: #fe523a;
  padding: 100px 0 200px;
}

.cart-page .container {
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 15px black;
}

.cart-page .heading {
  border-radius: 20px;
  background-color: #fe523a;
  padding: 5px 50px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 20px;
}

hr {
  border: 1px solid black;
}

.cart-page .cart-items {
  margin: 10px 0;
}

.cart-page .box .product-details {
  width: 33%;
  padding: 15px;
  padding-left: 0;
}

.cart-page .box .product-details .image {
  width: 50%;
  padding: 15px;
}

.cart-page .box .product-details .image img {
  width: 100%;
}

.cart-page .box .product-details .details {
  width: 50%;
  padding: 15px;
}

.cart-page .box .product-details .details h2 {
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
}

.cart-page .box .product-details .details span {
  display: inline-block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 500;
  padding: 6px 0;
}

.cart-page .box .product-details .details span span {
  color: #fe523a;
}

.cart-page .box .product-details .details button {
  position: relative;
  display: block;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #fe523a;
  font-size: 16px;
  font-weight: 600;
}

.cart-page .box .product-details .details button::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 0;
  background-color: #fe523a;
  bottom: 0;
  right: 0;
  transition: all 0.5s;
}

.cart-page .box .product-details .details button:hover {
  letter-spacing: 1px;
}

.cart-page .box .product-details .details button:hover::after {
  width: 100%;
  left: 0;
}

.cart-page .box .quantity {
  width: 33%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-page .box .quantity label {
  font-size: 17px;
  font-weight: 600;
  color: black;
  padding-right: 5px;
}

.cart-page .box .quantity input {
  width: 50px;
  height: 25px;
  border: 2px solid black;
  text-align: center;
  outline: none;
  color: black;
  font-size: 16px;
  font-weight: 500;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.cart-page .box .price {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 15px;
  padding-right: 50px;
}

.cart-page .blank {
  width: 34%;
}

.cart-page .total-part {
  width: 66%;
  padding: 50px 0 30px;
}

.cart-page .total-part hr {
  border: 2px solid #fe523a;
  width: 100%;
}

.cart-page .total-part {
  padding-left: 110px;
  padding-right: 50px;
}

.cart-page .total-part .total {
  padding: 30px 30px 5px;
  padding-right: 0;
  text-transform: capitalize;
}

.cart-page .total-part .total h4 {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.cart-page .total-part .total span {
  color: black;
  font-size: 18px;
  font-weight: 600;
}

.cart-page .total-part button {
  position: relative;
  background-color: #fe523a;
  padding: 12px 30px;
  border: none;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  display: block;
  margin-top: 50px;
  margin-left: auto;
  overflow: hidden;
}

.cart-page .total-part button:hover {
  transform: scale(103%);
}


.cart-page .total-part button::after {
  position: absolute;
  content: "";
  background-color: rgb(255, 255, 255, 0.5);
  top: -75px;
  left: -56px;
  width: 50px;
  height: 200px;
  transform: rotate(150deg);
  transition: all 0.8s;
}

.cart-page .total-part button:hover::after {
  left: 218px;
}